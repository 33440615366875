import { useReactiveVar } from '@apollo/client';
import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import {
  IBetLimitPost,
  TABLE_BETLIMIT_POST,
} from '../../../../../common/api/mutations/betlimit_mutation';
import { isUpdatedVar } from '../../../../../common/apollo';
import { ButtonCustom } from '../../../../../components/button';
import { useTranslation } from 'react-i18next';

interface IModalProps {
  setRegModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TableBetLimitAddModal: React.FC<IModalProps> = ({
  setRegModal,
}) => {
  const { register, getValues, handleSubmit, control } = useForm<IBetLimitPost>(
    { mode: 'onChange' }
  );
  const { t } = useTranslation(['page']);
  // const { isSubmitting } = useFormState({ control }); // Not properly working don't know why
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isUpdated = useReactiveVar(isUpdatedVar);

  const clickModalClose = () => {
    setRegModal((current) => !current);
  };
  const onSubmit = () => {
    setIsButtonDisabled(true);
    const {
      name,
      bp_min,
      bp_max,
      pair_min,
      pair_max,
      tie_min,
      tie_max,
      super6_min,
      super6_max,
    } = getValues();

    const data = {
      id: '',
      name: name,
      currency: '',
      bp_min: bp_min,
      bp_max: bp_max,
      pair_min: pair_min,
      pair_max: pair_max,
      tie_min: tie_min,
      tie_max: tie_max,
      super6_min: super6_min,
      super6_max: super6_max,
    };
    TABLE_BETLIMIT_POST(data)
      .then((res: AxiosResponse | any) => {
        if (res.data.data[0].code === 1) {
          alert('Registration Success');
          isUpdatedVar(!isUpdated);
        } else if (res.data.data[0].code === 0) {
          alert('Registration Failed');
          isUpdatedVar(!isUpdated);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };
  return (
    <div className='box-border fixed top-0 bottom-0 left-0 right-0 z-10 overflow-auto outline-0'>
      <div className='fixed w-full h-full backdrop-blur-sm'>
        <div className='box-border relative m-24 bg-inherit top-12'>
          <div className='flex'>
            <div className='mx-auto border rounded-md shadow-lg lg:col-span-2'>
              <div className='flex flex-row justify-between px-6 py-3 text-lg font-medium bg-white border-b'>
                <div className='text-blue-500'>한도 추가</div>
                <GrClose
                  className='mt-1 cursor-pointer'
                  onClick={clickModalClose}
                />
              </div>
              <div className='p-6 bg-white shadow-lg'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    type='text'
                    name='username'
                    className='block w-0 h-0 border-0'
                  />
                  <input type='password' className='block w-0 h-0 border-0' />
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Limit Name')}
                        </div>
                      </div>
                      <input
                        {...register('name')}
                        minLength={3}
                        maxLength={15}
                        type='text'
                        className='forminput'
                        placeholder='Enter Account'
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('B.P Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('bp_min')}
                        type='number'
                        className='forminput'
                        placeholder='BP Minimum Input'
                      />
                    </div>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>{t('B.P Max')}</div>
                      </div>
                      <input
                        {...register('bp_max')}
                        type='number'
                        className='forminput'
                        placeholder='BP Max Input'
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Pair Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('pair_min')}
                        type='number'
                        className='forminput'
                        placeholder='Pair Minimum Input'
                      />
                    </div>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Pair Max')}
                        </div>
                      </div>
                      <input
                        {...register('pair_max')}
                        type='number'
                        className='forminput'
                        placeholder='Pair Max Input'
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className=' form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Tie Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('tie_min')}
                        type='number'
                        className='forminput'
                        placeholder='Tie Minimum Input'
                      />
                    </div>
                    <div className=' form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>{t('Tie Up')}</div>
                      </div>
                      <input
                        {...register('tie_max')}
                        type='number'
                        className='forminput'
                        placeholder='Tie Max Input'
                      />
                    </div>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Super 6 Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('super6_min')}
                        type='number'
                        className='forminput'
                        placeholder='Super 6 Minimum Input'
                      />
                    </div>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Super 6 Max')}
                        </div>
                      </div>
                      <input
                        {...register('super6_max')}
                        type='number'
                        className='forminput'
                        placeholder='Super 6 Max Input'
                      />
                    </div>
                  </div>
                  <ButtonCustom
                    canClick={!isButtonDisabled}
                    actionText={'Limit Registration'}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
