import { useReactiveVar } from '@apollo/client';
import { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import {
  IBetLimitPost,
  USER_BETLIMIT_MOD,
} from '../../../../../common/api/mutations/betlimit_mutation';
import {
  CURRENCIES_GET_QUERY,
  ICurrencyRes,
} from '../../../../../common/api/queries/currency_query';
import { client, isUpdatedVar, limitData } from '../../../../../common/apollo';
import { ButtonCustom } from '../../../../../components/button';
import { FormError } from '../../../../../components/form-error';
import { useTranslation } from 'react-i18next';

interface IModalProps {
  setModModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserBetLimitModModal: React.FC<IModalProps> = ({
  setModModal,
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IBetLimitPost>({ mode: 'onChange' });
  const { t } = useTranslation(['page']);
  const { isSubmitting } = useFormState({ control });
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [currencyList, setCurrencyList] = useState(Array<ICurrencyRes>);

  const selectedLimitData = useReactiveVar(limitData);
  const isUpdated = useReactiveVar(isUpdatedVar);

  const clickModalClose = () => {
    setModModal((current) => !current);
  };

  const getCurrenciesList = () => {
    client.query({ query: CURRENCIES_GET_QUERY }).then((res) => {
      if (res.data['currenciesGet'].data) {
        setCurrencyList(res.data['currenciesGet'].data);
      }
    });
  };

  const currencyElemList = currencyList.map((elem) => {
    return (
      <option key={elem.currency_code} value={elem.currency_code}>
        {elem.currency_code}
      </option>
    );
  });

  const handleCurrencyChoice = (e: React.SyntheticEvent<HTMLOptionElement>) => {
    setSelectedCurrency(e.currentTarget.value);
  };

  const onSubmit = () => {
    const {
      name,
      bp_min,
      bp_max,
      pair_min,
      pair_max,
      tie_min,
      tie_max,
      currency,
      super6_min,
      super6_max,
    } = getValues();

    const data = {
      id: selectedLimitData?.id,
      name: name,
      currency: currency,
      bp_min: bp_min,
      bp_max: bp_max,
      pair_min: pair_min,
      pair_max: pair_max,
      tie_min: tie_min,
      tie_max: tie_max,
      super6_min: super6_min,
      super6_max: super6_max,
    };
    // console.log(data);
    USER_BETLIMIT_MOD(data)
      .then((res: AxiosResponse | any) => {
        if (res.data.data[0].code === 1) {
          alert('Registration Success');
          isUpdatedVar(!isUpdated);
        } else if (res.data.data[0].code === 0) {
          alert('Registration Failed');
          isUpdatedVar(!isUpdated);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCurrenciesList();
  }, []);

  return (
    <div className='box-border fixed top-0 bottom-0 left-0 right-0 z-10 overflow-auto outline-0'>
      <div className='fixed w-full h-full backdrop-blur-sm'>
        <div className='box-border relative m-24 bg-inherit top-12'>
          <div className='flex'>
            <div className='mx-auto border rounded-md shadow-lg lg:col-span-2'>
              <div className='flex flex-row justify-between px-6 py-3 text-lg font-medium bg-white border-b'>
                <div className='text-blue-500'>한도 수정</div>
                <GrClose
                  className='mt-1 cursor-pointer'
                  onClick={clickModalClose}
                />
              </div>
              <div className='p-6 bg-white shadow-lg'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    type='text'
                    name='username'
                    className='block w-0 h-0 border-0'
                  />
                  <input type='password' className='block w-0 h-0 border-0' />
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Limit Name')}
                        </div>
                      </div>
                      <input
                        {...register('name')}
                        minLength={5}
                        maxLength={15}
                        type='text'
                        className='forminput'
                        defaultValue={selectedLimitData?.name}
                        placeholder='Enter Account'
                      />
                    </div>
                    <div className='mb-4'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Currency')}
                        </div>
                        {errors.currency?.message && (
                          <FormError errorMessage={errors.currency.message} />
                        )}
                      </div>
                      <select
                        className='forminput'
                        required
                        {...register('currency', {
                          onChange: (e) => handleCurrencyChoice(e),
                        })}
                      >
                        <option
                          key={selectedLimitData?.currency}
                          value={selectedLimitData?.currency}
                        >
                          {selectedLimitData?.currency}
                        </option>
                        {/* {currencyList?.map((elem, index) => {
                          return (
                            <option
                              key={`currency_code_${index}`}
                              value={String(elem.currency_code)}
                            >
                              {String(elem.currency_code)}
                            </option>
                          );
                        })} */}
                        {currencyElemList.filter((elem) => {
                          return elem.key !== selectedLimitData?.currency;
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('B.P Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('bp_min')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.bp_min}
                        placeholder='BP Minimum Input'
                      />
                    </div>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>{t('B.P Max')}</div>
                      </div>
                      <input
                        {...register('bp_max')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.bp_max}
                        placeholder='BP Max Input'
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Pair Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('pair_min')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.pair_min}
                        placeholder='Pair Minimum Input'
                      />
                    </div>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Pair Max')}
                        </div>
                      </div>
                      <input
                        {...register('pair_max')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.pair_max}
                        placeholder='Pair Max Input'
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className=' form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Tie Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('tie_min')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.tie_min}
                        placeholder='Tie Minimum Input'
                      />
                    </div>
                    <div className=' form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>{t('Tie Max')}</div>
                      </div>
                      <input
                        {...register('tie_max')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.tie_max}
                        placeholder='Tie Max Input'
                      />
                    </div>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Super 6 Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('super6_min')}
                        type='number'
                        defaultValue={selectedLimitData?.super6_min}
                        className='forminput'
                        placeholder='Super 6 Minimum Input'
                      />
                    </div>
                    <div className='mb-6 form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Super 6 Max')}
                        </div>
                      </div>
                      <input
                        {...register('super6_max')}
                        type='number'
                        defaultValue={selectedLimitData?.super6_max}
                        className='forminput'
                        placeholder='Super 6 Max Input'
                      />
                    </div>
                  </div>
                  <ButtonCustom
                    canClick={!isSubmitting}
                    actionText={'Modify User Limit'}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
