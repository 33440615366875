import { useReactiveVar } from '@apollo/client';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import {
  IBetLimitResponse,
  USER_LIMITS_GET_QUERY,
} from '../../../../common/api/queries/betlimit_query';
import {
  isUpdatedVar,
  langVar,
  limitData,
  routeTitleVar,
  uBetLimitList,
} from '../../../../common/apollo';
import { UserBetLimitAddModal } from './modal/ubetLimitAddModal';
import { UserBetLimitModModal } from './modal/ubetlimitModModal';
import { useTranslation } from 'react-i18next';

export const UserBetLimitList = () => {
  const { t } = useTranslation(['page']);
  const selectedLang = useReactiveVar(langVar);
  const [regModal, setRegModal] = useState(false);
  const [modModal, setModModal] = useState(false);

  const data = useReactiveVar(uBetLimitList);
  const isUpdated = useReactiveVar(isUpdatedVar);
  useReactiveVar(limitData);
  useReactiveVar(routeTitleVar);

  const onClickRegModal = () => {
    setRegModal(!regModal);
  };

  const onClickUpdateModal = (data: IBetLimitResponse) => {
    setModModal(!modModal);
    limitData(data);
  };

  const getLimitList = () => {
    USER_LIMITS_GET_QUERY().then((res: AxiosResponse | any) => {
      if (res.data.data[0].code === 1) {
        uBetLimitList(res.data.data[0].data.list);
      }
    });
  };

  const columnHelper = createColumnHelper<IBetLimitResponse>();
  const columns = [
    columnHelper.accessor('id', {
      header: String(t('Limit ID')),
    }),
    columnHelper.accessor('name', {
      header: String(t('Limit Name')),
    }),
    columnHelper.accessor('currency', {
      header: String(t('Call')),
    }),
    columnHelper.accessor('bp_min', {
      header: String(t('B.P Minimum')),
    }),
    columnHelper.accessor('bp_max', {
      header: String(t('B.P Max')),
    }),
    columnHelper.accessor('pair_min', {
      header: String(t('Pair Minimum')),
    }),
    columnHelper.accessor('pair_max', {
      header: String(t('Pair Max')),
    }),
    columnHelper.accessor('tie_min', {
      header: String(t('Tie Minimum')),
    }),
    columnHelper.accessor('tie_max', {
      header: String(t('Tie Max')),
    }),
    columnHelper.accessor('super6_min', {
      header: String(t('Super 6 Min')),
    }),
    columnHelper.accessor('super6_max', {
      header: String(t('Super 6 Max')),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    routeTitleVar(String(t('Limit Setting (User)')));
    getLimitList();
  }, [selectedLang]);

  useEffect(() => {
    setModModal(false);
    setRegModal(false);
    getLimitList();
  }, [isUpdated]);

  return (
    <div>
      <div>
        <div className='mt-5 border-l border-r rounded-md shadow-lg'>
          <div className='flex flex-row items-center justify-between border-b'>
            <div className='px-6 py-3'>
              <div className='text-lg font-semibold text-gray-500'>
                {t('Limit List')}
              </div>
              <div className='text-gray-400'>{t('Add & Edit')}</div>
            </div>
            <button className='mr-3 btnmodal' onClick={onClickRegModal}>
              {t('Add A Limit')}
            </button>
          </div>
          <div className='flex flex-col bg-white'>
            <div className='overflow-x-auto'>
              <div className='min-w-full'>
                <table className='w-full'>
                  <thead className='border-b bg-[#245c81]'>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className=''>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            className='px-2 py-2 text-sm font-bold text-left text-white whitespace-nowrap'
                          >
                            <div className='text-center'>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </div>
                          </th>
                        ))}
                        <th
                          style={{ cursor: 'default' }}
                          className='px-2 py-2 text-sm font-bold text-center text-white'
                        >
                          {t('Edit')}
                        </th>
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                      <tr
                        key={row.id}
                        className={`bg-white border-b transition duration-300 ease-in-out hover:bg-gray-300 whitespace-nowrap text-center`}
                      >
                        {row.getVisibleCells().map((cell) => {
                          if (
                            cell.column.columnDef.header === t('B.P Minimum')
                          ) {
                            return (
                              <td
                                key={cell.id}
                                className='px-4 py-2 text-xs font-normal whitespace-nowrap'
                              >
                                {cell.row.original.bp_min.toLocaleString()}
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('B.P Max')
                          ) {
                            return (
                              <td
                                key={cell.id}
                                className='px-4 py-2 text-xs font-normal whitespace-nowrap'
                              >
                                {cell.row.original.bp_max.toLocaleString()}
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Pair Minimum')
                          ) {
                            return (
                              <td
                                key={cell.id}
                                className='px-4 py-2 text-xs font-normal whitespace-nowrap'
                              >
                                {cell.row.original.pair_min.toLocaleString()}
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Pair Max')
                          ) {
                            return (
                              <td
                                key={cell.id}
                                className='px-4 py-2 text-xs font-normal whitespace-nowrap'
                              >
                                {cell.row.original.pair_max.toLocaleString()}
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Tie Minimum')
                          ) {
                            return (
                              <td
                                key={cell.id}
                                className='py-2 text-xs font-normal whitespace-nowrap'
                              >
                                {cell.row.original.tie_min.toLocaleString()}
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Tie Max')
                          ) {
                            return (
                              <td
                                key={cell.id}
                                className='py-2 text-xs font-normal whitespace-nowrap'
                              >
                                {cell.row.original.tie_max.toLocaleString()}
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header ===
                            t('Super 6 Minimum')
                          ) {
                            return (
                              <td
                                key={cell.id}
                                className='py-2 text-xs font-normal whitespace-nowrap'
                              >
                                {cell.row.original.super6_min.toLocaleString()}
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header ===
                            t('Super 6 Maximum')
                          ) {
                            return (
                              <td
                                key={cell.id}
                                className='py-2 text-xs font-normal whitespace-nowrap'
                              >
                                {cell.row.original.super6_max.toLocaleString()}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={cell.id}
                                className='px-4 py-2 text-xs text-center text-gray-900'
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          }
                        })}
                        <td className='flex items-center justify-center p-2'>
                          <div className='p-2 rounded cursor-pointer hover:bg-gray-100'>
                            <AiFillEdit
                              className='text-base'
                              onClick={() => onClickUpdateModal(row.original)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='flex flex-wrap items-center justify-center gap-2 py-4'>
            <button
              className='p-1 border rounded'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='p-1 border rounded'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='p-1 border rounded'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='p-1 border rounded'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
            <span className='flex items-center gap-1'>
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <span className='flex items-center gap-1'>
              | Go to page:
              <input
                type='number'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className='w-16 p-1 border rounded'
              />
            </span>
            <select
              className='p-1 border rounded'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        {regModal ? <UserBetLimitAddModal setRegModal={setRegModal} /> : null}
        {modModal ? <UserBetLimitModModal setModModal={setModModal} /> : null}
      </div>
    </div>
  );
};
