import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export interface IBetLimitPost {
  id: string | undefined;
  name: string | undefined;
  currency: string;
  bp_min: number;
  bp_max: number;
  pair_min: number;
  pair_max: number;
  tie_min: number;
  tie_max: number;
  super6_min?: number;
  super6_max?: number;
}

export interface IBetLimit {
  id: string | undefined;
  name: string;
  currency: string;
  ut_bp_min: number;
  bp_min: number;
  bp_max: number;
  ut_pair_min: number;
  pair_min: number;
  pair_max: number;
  ut_tie_min: number;
  tie_min: number;
  tie_max: number;
  super6_min: number;
  super6_max: number;
}

export const USER_BETLIMIT_POST = async (data: IBetLimitPost) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/betlimit-mutation/USER_LIMIT_ADD_URI',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const USER_BETLIMIT_MOD = async (data: IBetLimitPost) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/betlimit-mutation/USER_LIMIT_EDIT_URI',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const TABLE_BETLIMIT_POST = async (data: IBetLimitPost) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/betlimit-mutation/LIMIT_ADD_URI',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const TABLE_BETLIMIT_MOD = async (data: IBetLimitPost) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/betlimit-mutation/LIMIT_EDIT_URI',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const USER_TLIMIT_POST = async (data: IBetLimitPost) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/betlimit-mutation/USER_TLIMIT_ADD_URI',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const USER_TLIMIT_MOD = async (data: IBetLimitPost) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/betlimit-mutation/USER_TLIMIT_EDIT_URI',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
